import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

const propTypes = {}

const defaultProps = {}

const HelmetHelper = ({ children, ...props }) => (
	<Fragment>
		<Helmet
			titleTemplate="%s | Free Association"
			defaultTitle="Free Association"
			{...props}
		>
			<html lang="en" />
			<meta
				content="https://freeassociation.com/share-image-mark.png"
				property="og:image"
			/>
			<meta
				content="https://freeassociation.com/share-image-mark.png"
				property="twitter:image:src"
			/>
			<meta name="twitter:card" content="summary_large_image" />
			<meta content="website" property="og:type" />
			<meta content="Free Association" property="og:site_name" />
			<meta name="twitter:site" content="@FreeAssociation" />
		</Helmet>
		<Helmet
			titleTemplate="%s | Free Association"
			defaultTitle="Free Association"
			{...props}
		>
			{children}
		</Helmet>
	</Fragment>
)

HelmetHelper.propTypes = propTypes
HelmetHelper.defaultProps = defaultProps

export default HelmetHelper
